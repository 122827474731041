import React from 'react'
import PropTypes from 'prop-types'

import { graphql } from 'gatsby'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'

const Terms = ({ data }) => (
  <Layout>
    <SEO title="Terms and Conditions" />
    <div dangerouslySetInnerHTML={{ __html: data.iubendaDocument.termsAndConditions }} />
  </Layout>
)

Terms.propTypes = {
  data: PropTypes.shape({
    iubendaDocument: PropTypes.shape({
      termsAndConditions: PropTypes.string,
    }),
  }).isRequired,
}

export default Terms

export const query = graphql`
query Terms {
  iubendaDocument(documentId: {eq: "28834528"}) {
    termsAndConditions
  }
}
`
